import {PERMISSION_ACTIONS, PERMISSION_SUBJECTS} from './permissionsUtils';
import {USER_ROLES} from '../../../views/pages/users/userUtils';
import {store} from '../../../services/redux/storeConfig/store';

export const arrangeUserSession = (userData) => {
    let response = {
        user_id: null,
        email: null,
        name: null,
        lastname: null,
        avatar: null,
        role_slug: null,
        role: null
    };
    if (userData) {
        response.user_id = userData.id;
        response.email = userData.correo;
        response.avatar = userData.avatar;
        if (userData.perfil) {
            response.name = userData.perfil.nombres
            response.lastname = userData.perfil.apellidos
        }
        if (userData.rol) {
            response.role_slug = userData.rol.slug
            response.role = userData.rol.titulo
        }
    }

    return response;
}

export const defineRulesFor = (auth) => {
    const commonPermissions = [
        {
            subject: PERMISSION_SUBJECTS.home.name,
            action: PERMISSION_ACTIONS.read.name
        },
        {
            subject: PERMISSION_SUBJECTS.calendar.name,
            action: PERMISSION_ACTIONS.read.name
        },
        {
            subject: PERMISSION_SUBJECTS.calendar.name,
            action: PERMISSION_ACTIONS.create.name
        },
        {
            subject: PERMISSION_SUBJECTS.calendar.name,
            action: PERMISSION_ACTIONS.update.name
        },
        {
            subject: PERMISSION_SUBJECTS.calendar.name,
            action: PERMISSION_ACTIONS.delete.name
        },
        {
            subject: PERMISSION_SUBJECTS.notifications.name,
            action: PERMISSION_ACTIONS.read.name
        },
        {
            subject: PERMISSION_SUBJECTS.notifications.name,
            action: PERMISSION_ACTIONS.export.name
        },
        {
            subject: PERMISSION_SUBJECTS.emails.name,
            action: PERMISSION_ACTIONS.read.name
        },
        {
            subject: PERMISSION_SUBJECTS.emails.name,
            action: PERMISSION_ACTIONS.export.name
        },
    ];
    const permissions = [];
    switch (auth.role_slug.toUpperCase()) {
        case USER_ROLES.superUsuario.slug:
            Object.keys(PERMISSION_SUBJECTS).forEach((subjectKey) => {
                const subject = PERMISSION_SUBJECTS[subjectKey].name;
                Object.keys(PERMISSION_ACTIONS).forEach((actionKey) => {
                    const action = PERMISSION_ACTIONS[actionKey].name;
                    permissions.push({
                        action: action,
                        subject: subject
                    })
                });
            });
            break;
        case USER_ROLES.contabilidadDirector.slug:
        case USER_ROLES.contabilidadGerente.slug:
        case USER_ROLES.contabilidadSupervisor.slug:
        case USER_ROLES.contabilidadOperativo.slug:
            permissions.push(...commonPermissions);
            break;
        case USER_ROLES.tesoreriaDirector.slug:
        case USER_ROLES.tesoreriaGerente.slug:
        case USER_ROLES.tesoreriaSupervisor.slug:
        case USER_ROLES.tesoreriaOperativo.slug:
            permissions.push(...commonPermissions);
            break;
        case USER_ROLES.planeacionDirector.slug:
        case USER_ROLES.planeacionGerente.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.export.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.export.name
                },
            );
            break;
        case USER_ROLES.planeacionSupervisor.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.imports.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.import.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
            );
            break;
        case USER_ROLES.planeacionOperativo.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.imports.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.company.name,
                    action: PERMISSION_ACTIONS.import.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.paymentStrategies.name,
                    action: PERMISSION_ACTIONS.update.name
                },
            );
            break;
        case USER_ROLES.implementacionDirector.slug:
        case USER_ROLES.implementacionGerente.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.export.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.export.name
                },
            );
            break;
        case USER_ROLES.implementacionSupervisor.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.imports.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.import.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.import.name
                },
            );
            break;
        case USER_ROLES.implementacionOperativo.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.imports.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.client.name,
                    action: PERMISSION_ACTIONS.import.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.employee.name,
                    action: PERMISSION_ACTIONS.import.name
                },
            );
            break;
        case USER_ROLES.facturacionDirector.slug:
        case USER_ROLES.facturacionGerente.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.export.name
                },
            );
            break;
        case USER_ROLES.facturacionSupervisor.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
            );
            break;
        case USER_ROLES.facturacionOperativo.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payrollReceipts.name,
                    action: PERMISSION_ACTIONS.update.name
                },
            );
            break;
        case USER_ROLES.operacionDirector.slug:
        case USER_ROLES.operacionGerente.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.export.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.export.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.export.name
                },
            );
            break;
        case USER_ROLES.operacionSupervisor.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.imports.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.import.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.payrollSendToAuthorized.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.payrollSendToUnauthorized.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.bankLayoutSendToCreated.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.bankLayoutSendToCompleted.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.bankLayoutSendToGenerated.name
                },
            );
            break;
        case USER_ROLES.operacionOperativo.slug:
            permissions.push(
                ...commonPermissions,
                {
                    subject: PERMISSION_SUBJECTS.imports.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.incidents.name,
                    action: PERMISSION_ACTIONS.import.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.payrollSendToPendingAuthorize.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.bankLayoutSendToCreated.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.bankLayoutSendToCompleted.name
                },
                {
                    subject: PERMISSION_SUBJECTS.bankLayouts.name,
                    action: PERMISSION_ACTIONS.bankLayoutSendToGenerated.name
                },
            );
            break;
    }
    return permissions;
}

export const canDo = (props = {}) => {
    let canDo = false;
    const {action, subject} = props;
    const permissions = store.getState().auth.session.data.permissions;
    if (permissions) {
        canDo = permissions.filter((permission) => {
            return permission.action === action && permission.subject === subject
        }).length > 0;
    }
    return canDo;
}
