import {PERMISSION_SUBJECTS} from '../../components/nominex/Permissions/permissionsUtils';
import * as Icon from 'react-feather';
import React from 'react';
import {Mail} from "react-feather";

const navigationConfig = [
    {
        type: 'groupHeader',
        groupTitle: 'Clientes',
        subjects: [
            PERMISSION_SUBJECTS.client.name, 
            PERMISSION_SUBJECTS.employee.name, 
            PERMISSION_SUBJECTS.incidents.name, 
            PERMISSION_SUBJECTS.vacations.name,
        ]
    },
    {
        id: 'clientes',
        title: 'Clientes',
        type: 'item',
        icon: <Icon.Users size={20}/>,
        navLink: '/clients',
        subjects: [PERMISSION_SUBJECTS.client.name],
    },
    {
        id: 'empleados',
        title: 'Empleados',
        type: 'item',
        icon: <Icon.UserCheck size={20}/>,
        navLink: '/employees',
        subjects: [PERMISSION_SUBJECTS.employee.name],
    },
    {
        id: 'incidencias',
        title: 'Incidencias',
        type: 'item',
        icon: <Icon.Shield size={20}/>,
        navLink: '/incidents',
        subjects: [PERMISSION_SUBJECTS.incidents.name],
    },
    {
        id: 'vacaciones',
        title: 'Solicitudes Vacaciones',
        type: 'item',
        icon: <Icon.LifeBuoy size={20}/>,
        navLink: '/vacations',
        subjects: [PERMISSION_SUBJECTS.vacations.name],
    },
    {
        type: 'groupHeader',
        groupTitle: 'Nóminas',
        subjects: [
            PERMISSION_SUBJECTS.paymentStrategies.name, 
            PERMISSION_SUBJECTS.payroll.name, 
            PERMISSION_SUBJECTS.bankLayouts.name, 
            PERMISSION_SUBJECTS.payrollReceipts.name,
            PERMISSION_SUBJECTS.company.name, 
            PERMISSION_SUBJECTS.payrollConcept.name,
        ]
    },
    {
        id: 'estrategias_pago',
        title: 'Estrategias Pago',
        type: 'item',
        icon: <Icon.Settings size={20}/>,
        navLink: '/paymentStrategies',
        subjects: [PERMISSION_SUBJECTS.paymentStrategies.name],
    },
    {
        id: 'nomina',
        title: 'Nóminas',
        type: 'item',
        icon: <Icon.DollarSign size={20}/>,
        navLink: '/payrolls',
        subjects: [PERMISSION_SUBJECTS.payroll.name],
    },
    {
        id: 'layouts_bancarios',
        title: 'Layouts Bancarios',
        type: 'item',
        icon: <Icon.FileText size={20}/>,
        navLink: '/bankLayouts',
        subjects: [PERMISSION_SUBJECTS.bankLayouts.name],
    },
    {
        id: 'recibos_nomina',
        title: 'Recibos Nómina',
        type: 'item',
        icon: <Icon.Inbox size={20}/>,
        navLink: '/payrollReceipts',
        subjects: [PERMISSION_SUBJECTS.payrollReceipts.name],
    },
    {
        id: 'setting',
        title: 'Configuración',
        icon: <Icon.Sliders size={20}/>,
        type: 'collapse',
        subjects: [
            PERMISSION_SUBJECTS.company.name, 
            PERMISSION_SUBJECTS.payrollConcept.name,
        ],
        children: [
            {
                id: 'empresas',
                title: 'Empresas Pagadoras',
                type: 'item',
                icon: <Icon.Briefcase size={16}/>,
                navLink: '/companies',
                subjects: [PERMISSION_SUBJECTS.company.name],
            },
            {
                id: 'conceptos',
                title: 'Conceptos',
                icon: <Icon.Archive size={16}/>,
                type: 'item',
                navLink: '/concepts',
                subjects: [PERMISSION_SUBJECTS.payrollConcept.name],
            }
        ]
    },
    {
        type: 'groupHeader',
        groupTitle: 'Administración',
        subjects: [
            PERMISSION_SUBJECTS.user.name,
            PERMISSION_SUBJECTS.emails.name,
            PERMISSION_SUBJECTS.expedients.name, 
            PERMISSION_SUBJECTS.imports.name,
            PERMISSION_SUBJECTS.alerts.name,
            PERMISSION_SUBJECTS.products.name, 
            PERMISSION_SUBJECTS.intervalsPeriodicities.name,
            PERMISSION_SUBJECTS.fiscalParameters.name,
        ]
    },
    {
        id: 'usuarios',
        title: 'Usuarios',
        type: 'item',
        icon: <Icon.User size={20}/>,
        navLink: '/users',
        subjects: [PERMISSION_SUBJECTS.user.name],
    },
    {
        id: 'emails',
        title: 'Correos Electrónicos',
        icon: <Icon.Mail size={20}/>,
        type: 'item',
        navLink: '/emails',
        subjects: [PERMISSION_SUBJECTS.emails.name],
    },
    {
        id: 'expedients',
        title: 'Expedientes Digitales',
        type: 'item',
        icon: <Icon.File size={20}/>,
        navLink: '/expedients',
        subjects: [PERMISSION_SUBJECTS.expedients.name],
    },
    {
        id: 'imports',
        title: 'Importaciones',
        icon: <Icon.Upload size={20}/>,
        type: 'item',
        navLink: '/imports',
        subjects: [PERMISSION_SUBJECTS.imports.name],
    },
    {
        id: 'alerts',
        title: 'Alertas',
        icon: <Icon.Bell size={20}/>,
        type: 'item',
        navLink: '/alerts',
        subjects: [PERMISSION_SUBJECTS.alerts.name],
    },
    {
        id: 'products',
        title: 'Productos',
        icon: <Icon.Bookmark size={20}/>,
        type: 'item',
        navLink: '/products',
        subjects: [PERMISSION_SUBJECTS.products.name],
    },
    {
        id: 'periodicities',
        title: 'Intervalos Periodicidades',
        icon: <Icon.Calendar size={20}/>,
        type: 'item',
        navLink: '/intervalsPeriodicities',
        subjects: [PERMISSION_SUBJECTS.intervalsPeriodicities.name],
    },
    {
        id: 'fiscal_parameters',
        title: 'Parámetros Fiscales',
        icon: <Icon.Package size={20}/>,
        type: 'item',
        navLink: '/fiscalParameters',
        subjects: [PERMISSION_SUBJECTS.fiscalParameters.name],
    },
    {
        type: 'groupHeader',
        groupTitle: 'Configuración Sistema',
        subjects: [
            PERMISSION_SUBJECTS.bank.name,
            PERMISSION_SUBJECTS.clinic.name,
            PERMISSION_SUBJECTS.zipCode.name,
            PERMISSION_SUBJECTS.colonie.name,
            PERMISSION_SUBJECTS.department.name,
            PERMISSION_SUBJECTS.weekDay.name,
            PERMISSION_SUBJECTS.foreignExchange.name,
            PERMISSION_SUBJECTS.state.name,
            PERMISSION_SUBJECTS.maritalStatus.name,
            PERMISSION_SUBJECTS.genre.name,
            PERMISSION_SUBJECTS.municipality.name,
            PERMISSION_SUBJECTS.country.name,
            PERMISSION_SUBJECTS.periodicity.name,
            PERMISSION_SUBJECTS.job.name,
            PERMISSION_SUBJECTS.paymentStrategyItem.name,
            PERMISSION_SUBJECTS.familyType.name,
            PERMISSION_SUBJECTS.paymentType.name,
            PERMISSION_SUBJECTS.peopleType.name,
            PERMISSION_SUBJECTS.productType.name,
        ]
    },
    {
        id: 'catalogs',
        title: 'Catálogos',
        icon: <Icon.Database size={20}/>,
        type: 'collapse',
        subjects: [
            PERMISSION_SUBJECTS.bank.name,
            PERMISSION_SUBJECTS.clinic.name,
            PERMISSION_SUBJECTS.zipCode.name,
            PERMISSION_SUBJECTS.colonie.name,
            PERMISSION_SUBJECTS.department.name,
            PERMISSION_SUBJECTS.weekDay.name,
            PERMISSION_SUBJECTS.foreignExchange.name,
            PERMISSION_SUBJECTS.state.name,
            PERMISSION_SUBJECTS.maritalStatus.name,
            PERMISSION_SUBJECTS.genre.name,
            PERMISSION_SUBJECTS.municipality.name,
            PERMISSION_SUBJECTS.country.name,
            PERMISSION_SUBJECTS.periodicity.name,
            PERMISSION_SUBJECTS.job.name,
            PERMISSION_SUBJECTS.paymentStrategyItem.name,
            PERMISSION_SUBJECTS.familyType.name,
            PERMISSION_SUBJECTS.paymentType.name,
            PERMISSION_SUBJECTS.peopleType.name,
            PERMISSION_SUBJECTS.productType.name,
        ],
        children: [
            {
                id: 'read_bank',
                title: 'Bancos',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/banks',
                subjects: [PERMISSION_SUBJECTS.bank.name],
            },
            {
                id: 'read_clinic',
                title: 'Clínicas',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/clinics',
                subjects: [PERMISSION_SUBJECTS.clinic.name],
            },
            {
                id: 'read_zipCode',
                title: 'Códigos Postales',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/zipCodes',
                subjects: [PERMISSION_SUBJECTS.zipCode.name],
            },
            {
                id: 'read_colonie',
                title: 'Colonias',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/colonies',
                subjects: [PERMISSION_SUBJECTS.colonie.name],
            },
            {
                id: 'read_department',
                title: 'Departamentos',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/departments',
                subjects: [PERMISSION_SUBJECTS.department.name],
            },
            {
                id: 'read_weekDay',
                title: 'Días Semana',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/weekDays',
                subjects: [PERMISSION_SUBJECTS.weekDay.name],
            },
            {
                id: 'read_foreignExchange',
                title: 'Divisas',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/foreignExchanges',
                subjects: [PERMISSION_SUBJECTS.foreignExchange.name],
            },
            {
                id: 'read_state',
                title: 'Estados',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/states',
                subjects: [PERMISSION_SUBJECTS.state.name],
            },
            {
                id: 'read_maritalStatus',
                title: 'Estados Civiles',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/maritalStatuses',
                subjects: [PERMISSION_SUBJECTS.maritalStatus.name],
            },
            {
                id: 'read_genre',
                title: 'Géneros',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/genres',
                subjects: [PERMISSION_SUBJECTS.genre.name],
            },
            {
                id: 'read_municipality',
                title: 'Municipios',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/municipalities',
                subjects: [PERMISSION_SUBJECTS.municipality.name],
            },
            {
                id: 'read_country',
                title: 'Países',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/countries',
                subjects: [PERMISSION_SUBJECTS.country.name],
            },
            {
                id: 'read_periodicity',
                title: 'Periodicidades',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/periodicities',
                subjects: [PERMISSION_SUBJECTS.periodicity.name],
            },
            {
                id: 'read_job',
                title: 'Puestos',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/jobs',
                subjects: [PERMISSION_SUBJECTS.job.name],
            },
            {
                id: 'read_paymentStrategyItems',
                title: 'Rubros Estrategias Pago',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/paymentStrategyItems',
                subjects: [PERMISSION_SUBJECTS.paymentStrategyItem.name],
            },
            {
                id: 'read_familyType',
                title: 'Tipos Familiares',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/familyTypes',
                subjects: [PERMISSION_SUBJECTS.familyType.name],
            },
            {
                id: 'read_paymentType',
                title: 'Tipos Pagos',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/paymentTypes',
                subjects: [PERMISSION_SUBJECTS.paymentType.name],
            },
            {
                id: 'read_peopleType',
                title: 'Tipos Personas',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/peopleTypes',
                subjects: [PERMISSION_SUBJECTS.peopleType.name],
            },
            {
                id: 'read_productType',
                title: 'Tipos Productos',
                icon: <Icon.Bookmark size={16}/>,
                type: 'item',
                navLink: '/catalogs/productsTypes',
                subjects: [PERMISSION_SUBJECTS.productType.name],
            },
        ],
    }
];

export default navigationConfig;
